<template>
	<div class="w-full sm:w-1/2">
		<div class="leading-loose">
			<form
				class="max-w-xl m-4 p-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left"
        id="contactform"
			>
				<p
					class="text-primary-dark dark:text-primary-light text-2xl font-semibold mb-8"
				>
					Contact met Nav
				</p>
				<div class="">
					<label
						class="block text-lg text-primary-dark dark:text-primary-light mb-2"
						for="name"
						>Je volledige naam</label
					>
					<input
						class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
						id="name"
						name="name"
						type="text"
						required=""
						placeholder="Piet Heyn"
						aria-label="Name"
					/>
				</div>
				<div class="mt-6">
					<label
						class="block text-lg text-primary-dark dark:text-primary-light mb-2"
						for="email"
						>Je Email</label
					>
					<input
						class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
						id="email"
						name="email"
						type="text"
						required=""
						placeholder="Je Email"
						aria-label="Email"
					/>
				</div>
				<div class="mt-6">
					<label
						class="block text-lg text-primary-dark dark:text-primary-light mb-2"
						for="subject"
						>Onderwerp</label
					>
					<input
						class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
						id="subject"
						name="subject"
						type="text"
						required=""
						placeholder="Onderwerp van je bericht"
						aria-label="Onderwerp"
					/>
				</div>

				<div class="mt-6">
					<label
						class="block text-lg text-primary-dark dark:text-primary-light mb-2"
						for="message"
						>Je bericht</label
					>
					<textarea
						class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
						id="message"
						name="message"
						cols="14"
						rows="6"
						aria-label="Message"
					></textarea>
				</div>

				<div class="mt-6">
					<button
						class="px-4 py-2.5 text-white font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 rounded-lg"
						type="submit"
						aria-label="Verstuur bericht"
					>
						Verstuur bericht
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

const signupForm = document.getElementById('contactform');
const nameInput  = signupForm.querySelector('input[name=name]');
const emailInput = signupForm.querySelector('input[name=email]');

signupForm.addEventListener('submit', processSignupForm);
function processSignupForm(e) {
  e.preventDefault();

  // get our data
  const name  = nameInput.value;
  const email = emailInput.value;

  console.log({ name, email });

  // process the form!
  alert('Processing!');

}

export default {};
</script>

<style lang="scss" scoped></style>
