<template>
	<div class="container mx-auto mt-10 sm:mt-20">
		<!-- Project header -->
		<ProjectSingleHeader :singleProjectHeader="singleProjectHeader" />

		<!-- Project gallery -->
		<ProjectSingleGallery :projectImages="projectImages" />

		<!-- Project information -->
		<ProjectSingleInfo :projectInfo="projectInfo" />

		<!-- Project related projects -->
		<ProjectSingleRelatedProjects :relatedProject="relatedProject" />
	</div>
</template>

<script>
import feather from 'feather-icons';
import ProjectSingleHeader from '../components/ProjectSingleHeader.vue';
import ProjectSingleGallery from '../components/ProjectSingleGallery.vue';
import ProjectSingleInfo from '../components/ProjectSingleInfo.vue';
import ProjectSingleRelatedProjects from '../components/ProjectSingleRelatedProjects.vue';

export default {
	name: 'Projects',
	components: {
		ProjectSingleHeader,
		ProjectSingleGallery,
		ProjectSingleInfo,
		ProjectSingleRelatedProjects,
	},
	data: () => {
		return {
			singleProjectHeader: {
				singleProjectTitle: 'Flekto.nl',
				singleProjectDate: 'Nov, 2021',
				singleProjectTag: 'Website',
			},
			projectImages: [
				{
					id: 1,
					title: 'Flekto.nl',
					img: require('@/assets/images/flekt.png'),
				},
				{
					id: 2,
					title: 'Jokasport.nl',
					img: require('@/assets/images/download.png'),
				},
				{
					id: 3,
					title: 'Felixflos.nl',
					img: require('@/assets/images/flekt.png'),
				},
			],
			projectInfo: {
				clientHeading: 'Over de klant',
				companyInfos: [
					{
						id: 1,
						title: 'Naam',
						details: 'Jokasport.nl',
					},
					{
						id: 2,
						title: 'Diensten',
						details: 'Sportartikelen & meerdere verkoopkanalen',
					},
					{
						id: 3,
						title: 'Webshop',
						details: 'https://www.jokasport.nl',
					}
				],
				objectivesHeading: 'Doel',
				objectivesDetails:
					'Jokasport is de oudste winkel voor Sportartikelen in Nederland, en het doel hierbij is natuurlijk online uitblinken in het verkopen van sportartikelen. En dit is dan ook precies waar Flekto.nl heeft kunnen bijstaan, door de webshop van Magento naar Woocommerce te migreren en te optimaal te hosten voor een shop van dit kaliber. Jokasport heeft duizenden producten, en verkoopt via verschillende verkoopkanalen die allemaal zijn verbonden met de webshop. ',
				technlogies: [
					{
						title: 'Tools & Technologies',
						techs: [
							'HTML',
							'CSS',
							'JavaScript',
							'Vue.js',
							'TailwindCSS',
							'AdobeXD',
						],
					},
				],
				projectDetailsHeading: 'Challenge',
				projectDetails: [
					{
						id: 1,
						details:
							'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt totam dolorum, ducimus obcaecati, voluptas facilis molestias nobis ut quam natus similique inventore excepturi optio ipsa deleniti fugit illo. Unde, amet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum illo necessitatibus perspiciatis! Aperiam perferendis labore temporibus, eos culpa corporis recusandae quas, fuga voluptatibus nesciunt odit libero tenetur neque consequatur ea.',
					},
					{
						id: 2,
						details:
							'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?',
					},
					{
						id: 3,
						details:
							'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?',
					},
					{
						id: 4,
						details:
							'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt totam dolorum, ducimus obcaecati, voluptas facilis molestias nobis ut quam natus similique inventore excepturi optio ipsa deleniti fugit illo. Unde, amet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum illo necessitatibus perspiciatis! Aperiam perferendis labore temporibus, eos culpa corporis recusandae quas, fuga voluptatibus nesciunt odit libero tenetur neque consequatur ea.',
					},
				],
				socialSharingsHeading: 'Share This',
				socialSharings: [
					{
						id: 1,
						name: 'Twitter',
						icon: 'twitter',
						url: 'https://www.twitter.com/navvvv',
					},
					{
						id: 2,
						name: 'Instagram',
						icon: 'instagram',
						url: 'https://www.instagram.com/nav_appaiya',
					},
					{
						id: 3,
						name: 'Facebook',
						icon: 'facebook',
						url: 'https://www.facebook.com/nav-appaiya',
					},
					{
						id: 4,
						name: 'LinkedIn',
						icon: 'linkedin',
						url: 'https://www.linkedin.com/in/nav-appaiya-6bb7b975/',
					}
				],
			},
			relatedProject: {
				relatedProjectsHeading: 'Gerelateerde Projects',
				relatedProjects: [
					{
						id: 1,
						title: 'Rikkimode',
						img: require('@/assets/images/brands/rikkimode.png'),
            url: 'https://rikkimode.nl',
					},
					{
						id: 2,
						title: 'Meprint.nl',
						img: require('@/assets/images/brands/meprint.png'),
            url: 'https://meprint.nl',
					},
					{
						id: 3,
						title: 'Sports-supplier.nl',
						img: require('@/assets/images/brands/sports_supplier.svg'),
            url: 'https://sports-supplier.nl',
					},
					{
						id: 4,
						title: 'Flekto.nl',
						img: require('@/assets/images/flekt.png'),
            url: 'https://flekto.nl',
					},
				],
			},
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
